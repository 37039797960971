<script setup lang="ts">
/**
 * @author Giorgi Tseradze
 */
import { onMounted, ref } from "vue";
import type { Ref } from "vue";
import type { SecondaryDataInterface } from "@/types/ContentType";

const StatisticsCard = defineAsyncComponent(
  () => import("@/components/smartComponents/Statistics/parts/StatisticsCard.vue")
);

import { Data } from "./ts/Statistics.interface";

const props = defineProps<{
  contentData: SecondaryDataInterface;
}>();

const newArr: Ref<Partial<Data>[] | undefined> = ref([]);

/** Separate numeric, nonnumeric data and title */
const handleData = () => {
  newArr.value = props?.contentData?.data?.list?.[0]?.tooltip?.map((x) => {
    let newVal = null;
    if (!x.tipHover) {
      newVal = {
        title: x.tipText,
      };
    } else {
      const numericPart = parseInt(x.tipText);
      const nonNumericPart = x.tipText.substring(numericPart.toString().length);
      newVal = {
        num: numericPart,
        nonNum: nonNumericPart,
        teaser: x.tipHover,
      };
    }

    return newVal;
  });
};

onMounted(() => {
  handleData();
});
</script>

<template>
  <section class="statistics bg-white">
    <div class="container">
      <div class="statistics__header pos-rel">
        <div class="statistics__header-title">
          <h2 class="hl-1">{{ contentData?.conf?.header?.title }}</h2>
        </div>
        <div class="statistics__header-border pos-abs"></div>
        <div class="statistics__header-teaser d-flex">
          <p class="p-16">
            {{ contentData?.conf?.header?.teaser }}
          </p>
        </div>
      </div>
      <div class="statistics__inner">
        <div class="statistics__inner-row">
          <StatisticsCard v-for="(item, index) in newArr" :key="index" :content="item" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" src="./styles/statistics.scss" />
